document.addEventListener("DOMContentLoaded", function () {
    if (! /cookieagree=true/.test(document.cookie)) {
        document.querySelector("#cookie-info").classList.remove("hidden");
    }
});

function onCookieAgree() {
    const yearInSeconds = 31536000;
    document.cookie = "cookieagree=true; max-age=" + yearInSeconds + "; path=/;";
    document.querySelector("#cookie-info").classList.add("hidden");
}

document.getElementById("cookie-info__agree").addEventListener("click", onCookieAgree);
document.getElementById("cookie-info__agree").addEventListener("touchend", onCookieAgree);
